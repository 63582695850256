import styled from 'styled-components';

export const Checkmark = styled.div.attrs({
  className: 'Checkmark',
})`
  display: inline-block;
  transform: rotate(45deg);
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  border-bottom: ${({ borderColor, borderWidth }) => `${borderWidth} solid ${borderColor}`};
  border-right: ${({ borderColor, borderWidth }) => `${borderWidth} solid ${borderColor}`};
`;

export default Checkmark;
