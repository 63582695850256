import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { format } from "date-fns-tz";

import StyledButton from "./StyledButton";

const MobileEventInfo = styled.div.attrs({
  className: "MobileEventInfo",
})`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  flex: 1;

  @media only screen and (min-width: 800px) {
    display: none;
  }
`;

const DesktopEventInfo = styled.div.attrs({
  className: "DesktopEventInfo",
})`
  align-items: center;
  display: flex;
  flex-flow: column;
  flex: 1;
  padding: 55px 24px 30px 24px;

  @media only screen and (max-width: 799px) {
    display: none;
  }
`;

const TopBox = styled.div.attrs({
  className: "DesktopEventInfoTopBox",
})`
  width: 100%;
  max-width: 972px;

  padding: 2rem 3rem;
  margin-bottom: 28px;

  display: flex;
  justify-content: flex-start;
  align-items: right;
  flex-direction: column;

  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.35);
`;

const Columns = styled.div.attrs({
  className: "DesktopEventInfoColumns",
})`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
`;

const ColumnMargin = styled.div.attrs({
  className: "DesktopEventInfoColumnMargin",
})`
  flex: 1 0 12px;
`;

const LeftColumn = styled.div.attrs({
  className: "DesktopEventInfoLeftColumn",
})`
  width: 50%;

  display: flex;
  flex-flow: column nowrap;
`;

const RightColumn = styled.div.attrs({
  className: "DesktopEventInfoRightColumn",
})`
  width: 50%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: stretch;
`;

const MobileFulscreenSection = styled.div.attrs({
  className: "MobileEventInfoFullscreenSection",
})`
  min-height: 100vh;
  min-height: -webkit-fill-available;
  position: relative;
`;

const MobileHeadline = styled.div.attrs({
  className: "MobileEventInfoHeadline",
})`
  bottom: 14%;
  position: absolute;

  .DateTime {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    text-align: center;
  }
  .Description {
    text-align: center;
    margin: auto;
    width: 70%;
    font-size: 0.625 rem;
  }
`;

const DesktopBlurb = styled.div.attrs({
  className: "EventInfoDesktopBlurb",
})`
  text-align: justify;
  font-size: 14px;
  line-height: 200%;
  font-weight: normal;
`;

const BannerImage = styled.div.attrs({
  className: "EventInfoBannerImage",
})`
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    align-items: flex-start;
    display: flex;
    height: 100%;
    width: fit-content;

    position: relative;
  }

  img {
    border-radius: 4px;
  }

  @media only screen and (min-width: 800px) {
    flex: 3;
    min-height: 0;

    img {
      max-height: 100%;
      width: auto;
      object-fit: cover;
    }
  }

  @media only screen and (max-width: 799px) {
    width: 90%;

    margin: 1rem auto 2rem auto;
  }
`;

const ShowAndArtist = styled.div.attrs({
  className: "EventInfoShowAndArtist",
})`
  flex: 1;

  @media only screen and (max-width: 799px) {
    flex: 0;
    width: 80%;
    margin: auto;
  }

  .ShowName {
    text-transform: uppercase;
    font-size: 3rem;
    line-height: 2rem;
    margin-bottom: 0.5rem;
    margin-top: 0rem;
    user-select: none;
  }

  .ArtistName {
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0rem;
    font-weight: bold;
    user-select: none;
  }

  > * {
    margin: auto;

    @media only screen and (min-width: 800px) {
      width: fit-content;
      margin: unset;
    }
  }

  @media only screen and (min-width: 800px) {
    width: fit-content;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 1rem;

    .ShowName {
      margin-bottom: 1rem;
    }
  }
`;

const Time = styled.div.attrs({
  className: "EventInfoTime",
})`
  display: flex;
  margin: 0 auto 1rem auto;
  width: 80%;

  span {
    height: fit-content;
  }

  .DesktopDateTimeSection {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-contents: center;
    margin: 1rem 0;
    padding: 0 1.5rem;
    text-transform: uppercase;

    span:last-child {
      font-size: 1rem;
    }

    &:first-child {
      border-right: 1px solid;
    }
  }

  div {
    display: flex;
    flex-flow: row nowrap;
    height: fit-content;
  }

  @media only screen and (min-width: 800px) {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: fit-content;
    margin: unset;
    flex: 1;

    font-size: 2rem;
  }
`;

const LongDesc = styled.div.attrs({
  className: "EventInfoLongDesc",
})`
  margin-bottom: 2rem;
  .Description {
    width: 80%;
    margin: auto;
  }
`;

const DesktopButtons = styled.div.attrs({
  className: "EventInfoDesktopButtons",
})`
  display: flex;
  flex: row nowrap;
  justify-content: flex-end;
`;

const MobileTicketButton = styled.div.attrs({
  className: "EventInfoMobileTicketButton",
})`
  position: sticky;
  bottom: 7%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export default function EventInfo({
  artistBannerImage,
  artistName,
  description,
  moreInfo,
  eventDatetimeString,
  showName,
  ticketButton,
  worldDebutStyles,
}) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const parsedDate = new Date(eventDatetimeString);
  const formatInTimezone = (formatString) =>
    format(parsedDate, formatString, {
      timeZone,
    });

  const formattedAMPM = formatInTimezone("aaa");
  const formattedDayOfMonth = formatInTimezone("dd");
  const formattedHeadlineDate = formatInTimezone("MMMM dd y");
  const formattedHeadlineTime = formatInTimezone("h:mm zzz");
  const formattedMonth = formatInTimezone("LLLL");
  const formattedTime = formatInTimezone("h:mm");
  const formattedTimezone = formatInTimezone("zzz");

  const worldDebutButton = (
    <StyledButton
      isClickable={false}
      buttonText="World Debut"
      fontName={worldDebutStyles.buttonFont}
      textColor={worldDebutStyles.buttonTextColor}
      background={worldDebutStyles.buttonBackground}
      height="22px"
      width="139px"
    />
  );

  return (
    <>
      <MobileEventInfo>
        <MobileFulscreenSection>
          <MobileHeadline>
            <p className="DateTime MobileHeadlineText">
              {formattedHeadlineDate}
            </p>
            <p className="Description MobileHeadlineText">{description}</p>
          </MobileHeadline>
        </MobileFulscreenSection>
        <BannerImage>
          <div className="ArtistGradient">
            <img src={artistBannerImage} />
          </div>
        </BannerImage>
        <ShowAndArtist>
          <p className="ShowName MobileMoreInfoHeader">{showName}</p>
          <p className="ArtistName MobileMoreInfoHeader">{artistName}</p>
        </ShowAndArtist>
        <Time>
          <span className="DateTime MobileMoreInfoHeader">
            {formattedHeadlineDate} - {formattedHeadlineTime}
          </span>
        </Time>
        <LongDesc>
          <p className="Description MobileMoreInfo">
            {moreInfo}
          </p>
        </LongDesc>
        <MobileTicketButton>{ticketButton}</MobileTicketButton>
      </MobileEventInfo>
      <DesktopEventInfo>
        <TopBox>
          <div style={{ marginBottom: "1rem" }}>{worldDebutButton}</div>
          <Columns>
            <LeftColumn>
              <ShowAndArtist>
                <p className="ShowName DesktopHeadlineText">{showName}</p>
                <p className="ArtistName DesktopHeadlineText">{artistName}</p>
              </ShowAndArtist>
              <Time>
                <div className="DateTime DesktopHeadlineText">
                  <div className="DesktopDateTimeSection">
                    <span>{formattedDayOfMonth}</span>
                    <span>{formattedMonth}</span>
                  </div>
                  <div className="DesktopDateTimeSection">
                    <span>{formattedTime}</span>
                    <span>{`${formattedAMPM} - ${formattedTimezone}`}</span>
                  </div>
                </div>
              </Time>
            </LeftColumn>
            <RightColumn>
              <DesktopBlurb>
                <p className="Description DesktopHeadlineText">{description}</p>
              </DesktopBlurb>
              <DesktopButtons>{ticketButton}</DesktopButtons>
            </RightColumn>
            <ColumnMargin />
          </Columns>
        </TopBox>
        <BannerImage>
          <div className="ArtistGradient">
            <img src={artistBannerImage} />
          </div>
        </BannerImage>
      </DesktopEventInfo>
    </>
  );
}

EventInfo.propTypes = {
  artistBannerImage: PropTypes.string,
  artistName: PropTypes.string,
  description: PropTypes.string,
  moreInfo: PropTypes.string,
  eventDatetimeString: PropTypes.string,
  showName: PropTypes.string,
  ticketButton: PropTypes.object,
  worldDebutStyles: PropTypes.object,
};
