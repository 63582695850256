import styled from 'styled-components';

export const CheckoutColumnHeading = styled.p.attrs({
  className: 'CheckoutColumnHeading',
})`
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;

  color: ${(props) => props.color ?? '#C2C2C2'};
  text-align: center;
  text-transform: uppercase;

  padding: 16px 0px;
`;

export default CheckoutColumnHeading;
