import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Portrait = styled.div.attrs({
  className: "ArtistPortrait",
})`
  height: 100vh;
  width: 100%;

  background-image: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 90%,
      ${(props) => props.transitionColor} 100%
    ),
    url(${(props) => props.artistImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const MobileBackground = styled.div.attrs({
  className: "MobileBackground",
})`
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;

  @media only screen and (min-width: 800px) {
    display: none;
  }
`;

const MobileMoreInfoBackground = styled.div.attrs({
  className: "MobileMoreInfoBackground",
})`
  flex: 1 0 auto;
  background: linear-gradient(
    to bottom,
    ${(props) => props.transitionColor} 0%,
    ${(props) => props.color} 8%,
    ${(props) => props.color} 100%
  );
`;

const DesktopBackground = styled.div.attrs({
  className: "DesktopBackground",
})`
  height: 100%;
  width: 100%;

  background-image: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 80%,
      ${(props) => props.backgroundColor}40 100%
    ),
    linear-gradient(
      to top,
      transparent 0%,
      transparent 80%,
      ${(props) => props.backgroundColor}40 100%
    ),
    linear-gradient(
      to left,
      transparent 0%,
      transparent 93%,
      ${(props) => props.backgroundColor}20 100%
    ),
    linear-gradient(
      to right,
      transparent 0%,
      transparent 93%,
      ${(props) => props.backgroundColor}20 100%
    ),
    url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media only screen and (max-width: 799px) {
    display: none;
  }
`;

export default function EventBackground({
  artistImage,
  mobileMoreInfoBackgroundColor,
  mobileBackgroundTransitionColor,
  desktopBackgroundImage,
  desktopBackgroundColor,
}) {
  return (
    <>
      <MobileBackground>
        <Portrait
          artistImage={artistImage}
          transitionColor={mobileBackgroundTransitionColor}
        />
        <MobileMoreInfoBackground
          color={mobileMoreInfoBackgroundColor}
          transitionColor={mobileBackgroundTransitionColor}
        />
      </MobileBackground>
      <DesktopBackground
        onLoad={() => alert("sup")}
        backgroundImage={desktopBackgroundImage}
        backgroundColor={desktopBackgroundColor}
      />
    </>
  );
}

EventBackground.propTypes = {
  artistImage: PropTypes.string,
  mobileMoreInfoBackgroundColor: PropTypes.string,
  mobileBackgroundTransitionColor: PropTypes.string,
  desktopBackgroundImage: PropTypes.string,
  desktopBackgroundColor: PropTypes.string,
};
