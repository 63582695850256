import { useCallback, useState } from 'react';

export const useCreatePaymentIntent = () => {
  const [payment, setPayment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const createPaymentIntent = useCallback(({ amountsAndProducts }) => {
    setIsLoading(true);
    const orderDetails = amountsAndProducts.map((amountAndProduct) => {
      return {
        productId: amountAndProduct.product.id,
        priceId: amountAndProduct.product.priceId,
        amount: amountAndProduct.amount,
      }
    });
    const total = amountsAndProducts.reduce((sum, current) => {
      return sum + current.amount * current.product.price;
    }, 0);
    window.fetch("/.netlify/functions/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ orderDetails, total }),
    }).then(res => {
      return res.json();
    }).then(data => {
      setPayment(data);
      setError(null);
    }).catch(error => {
      setPayment(null);
      setError(error);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [setIsLoading, setPayment, setError]);
  return { createPaymentIntent, result: { payment, isLoading, error } };
};
