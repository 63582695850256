import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const ButtonStyles = styled.button.attrs({
  className: "StyledButton"
})`
  font-family: ${props => props.fontName};
  color: ${props => props.textColor};
  font-size: 12px;
  letter-spacing: 3px;
  text-transform: uppercase;
  background: ${props => props.background};
  border-radius: 4px;
  cursor: ${props => props.cursor};

  height: ${props => props.height};
  width: ${props => props.width};
  padding: 0px;

  white-space: nowrap;

  box-sizing: border-box;

  transition: all 100ms;
  opacity: 0.9;
  box-shadow: none;

  :hover {
    box-shadow: ${props => props.isClickable ? '0px 8px 18px 0px rgba(25, 27, 55, 0.4)' : 'unset'};
    transform: ${props => props.isClickable ? 'scale(1.05)' : 'unset'};
    opacity: ${props => props.isClickable ? '1' : '0.9'};
  }
`

function determineCursor(props) {
  if (typeof props.isClickable === 'boolean' && !props.isClickable) {
    return "default";
  }
  if (props.isDisabled) {
    return "not-allowed";
  }
  return "pointer";
}

export default function StyledButton(props) {
  const { buttonText, ...rest } = props;
  const cursor = determineCursor(props);
  const isClickable = props.isClickable === undefined ? true : props.isClickable;
  return (
    <ButtonStyles cursor={cursor} {...rest} isClickable={isClickable}>
      {buttonText}
    </ButtonStyles>
  );
}

StyledButton.propTypes = {
  buttonText: PropTypes.string,
  isDisabled: PropTypes.bool,
  isClickable: PropTypes.bool,

  background: PropTypes.string,
  fontName: PropTypes.string,
  textColor: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};
