import React from "react";
import styled from "styled-components";

const SpinnerContainer = styled.div.attrs({
  className: "SpinnerContainer",
})`
  display: inline-block;
  position: relative;
  width: ${({ size }) => size === 'sm' ? '40px' : '80px'}};
  height: ${({ size }) => size === 'sm' ? '40px' : '80px'};

  & div {
    display: inline-block;
    position: absolute;
    left: ${({ size }) => size === 'sm' ? '4px' : '8px'};
    width: ${({ size }) => size === 'sm' ? '8px' : '16px'};
    background: ${({ color }) => color != null ? color : '#B2B2B2'};
    animation: loading-${({ uid }) => uid} 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  & div:nth-child(1) {
    left: ${({ size }) => size === 'sm' ? '4px' : '8px'};
    animation-delay: -0.24s;
  }
  & div:nth-child(2) {
    left: ${({ size }) => size === 'sm' ? '16px' : '32px'};
    animation-delay: -0.12s;
  }
  & div:nth-child(3) {
    left: ${({ size }) => size === 'sm' ? '28px' : '56px'};
    animation-delay: 0s;
  }
  @keyframes loading-${({ uid }) => uid} {
    0% {
      top: ${({ size }) => size === 'sm' ? '4px' : '8px'};
      height: ${({ size }) => size === 'sm' ? '32px' : '64px'};
    }
    50%, 100% {
      top: ${({ size }) => size === 'sm' ? '12px' : '24px'};
      height: ${({ size }) => size === 'sm' ? '16px' : '32px'};
    }
  }
`;

export const Spinner = (props) => {
  return (
    <SpinnerContainer {...props}>
      <div />
      <div />
      <div />
    </SpinnerContainer>
  );
}

export default Spinner;
