import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import styled from "styled-components";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import Centered from "./Centered";
import EventSummary from "./EventSummary";
import OrderSummary from "./OrderSummary";
import SlidingForm from "./SlidingForm";

const stripe = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);

const ModalWrapper = styled.div.attrs({
  className: "CheckoutModalWrapper",
})`
  visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
  opacity: ${props => props.isOpen ? '1' : '0'};
  transition: visibility 0.3s, opacity 0.3s linear;
  position: fixed;
  z-index: 1;
  @media only screen and (min-width: 800px) {
    padding: clamp(40px, calc(15% - 80px), 100px);
  }
  @media only screen and (max-width: 799px) {
    padding: 0px;
  }
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    backdrop-filter: blur(10px) opacity(100%) brightness(0.3);
  }
  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: rgba(000, 000, 000, 0.8);
  }
`;

const CheckoutModalBox = styled.div.attrs({
  className: "CheckoutModalBox",
})`
  position: relative;
  width: 100%;
  @media only screen and (min-width: 800px) {
    max-height: 720px;
    border: 1px #A0A0A0 solid;
    border-radius: 4px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.35);
    max-width: 1920px;
  }
  height: 100%;
  overflow: auto;
`;

const ModalContent = styled.div.attrs({
  className: "CheckoutModalContent",
})`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 32px 0px;
  overflow: auto;
`;

const CheckoutContainer = styled.div.attrs({
  className: "CheckoutContainer",
})`
  display: grid;
  height: 100%;

  @media only screen and (min-width: 800px) {
    grid-template-rows: 1fr min(10%, 40px) minmax(120px, min-content) 2px 72px;
    grid-template-columns:
      clamp(16px, 5%, 40px)
      1fr
      clamp(16px, 5%, 40px)
      2px
      clamp(16px, 5%, 40px)
      1fr
      clamp(16px, 5%, 40px);
    grid-template-areas:
      ". form   . vline . event  ."
      ". form   . vline . .      ."
      ". form   . vline . order  ."
      ". hline1 . vline . hline2 ."
      ". button . vline . total .";
  }
  @media only screen and (max-width: 799px) {
    padding: 0px 32px;
    grid-template-rows:
      minmax(400px, 1fr)
      2px
      1fr
      2px
      minmax(120px, min-content)
      2px
      28px
      72px;
    grid-template-columns: auto;
    grid-template-areas:
      "event"
      "vline"
      "form"
      "hline1"
      "order"
      "hline2"
      "total"
      "button";
    row-gap: 16px;
  }
  justify-content: space-between;
  align-content: space-between;
  align-items: center;
`

const ModalCloseButton = styled.button.attrs({
  className: "ModalCloseButton"
})`
  position: absolute;
  top: 0;
  right: 0;
  height: 32px;
  width: 32px;
  background: transparent;
  color: white;
  z-index: 1;
`;

export const CheckoutModal = ({
  artistImage,
  artistName,
  eventDateTime,
  eventSlug,
  eventTitle,
  isOpen,
  onOverlayClick,
  onCloseClick,
}) => {
  const overlayRef = useRef(null);
  const secondOverlayRef = useRef(null);
  const handleBackdropClick = (event) => {
    if (event.target === overlayRef.current || event.target === secondOverlayRef.current) {
      onOverlayClick();
    }
  };
  const [amountsAndProducts, setAmountsAndProducts] = useState([]);
  return (
    <ModalWrapper isOpen={isOpen} onClick={handleBackdropClick} ref={overlayRef}>
      <Centered onClick={handleBackdropClick} ref={secondOverlayRef}>
        <CheckoutModalBox>
          <ModalCloseButton onClick={onCloseClick}>
            X
          </ModalCloseButton>
          <ModalContent>
            <CheckoutContainer>
              <Elements stripe={stripe}>
                <SlidingForm
                  amountsAndProducts={amountsAndProducts}
                  setAmountsAndProducts={setAmountsAndProducts}
                  eventSlug={eventSlug}
                />
              </Elements>
              <div style={{ gridArea: 'vline', height: '100%' }}>
                <div className="CheckoutLine" style={{ height: '100%' }} />
              </div>
              <div style={{ gridArea: 'event', height: '100%' }}>
                <EventSummary
                  artistImage={artistImage}
                  artistName={artistName}
                  eventDateTime={eventDateTime}
                  eventTitle={eventTitle}
                />
              </div>
              <OrderSummary amountsAndProducts={amountsAndProducts} />
            </CheckoutContainer>
          </ModalContent>
        </CheckoutModalBox>
      </Centered>
    </ModalWrapper>
  )
}

CheckoutModal.propTypes = {
  artistImage: PropTypes.string,
  isOpen: PropTypes.bool,
  onCloseClick: PropTypes.func,
  onOverlayClick: PropTypes.func,
  artistName: PropTypes.string,
  eventSlug: PropTypes.string,
  eventTitle: PropTypes.string,
  eventDateTime: PropTypes.string,
  checkoutBackgroundColor: PropTypes.string,
}

export default CheckoutModal;
