import PropTypes from "prop-types";
import React from 'react';
import styled from 'styled-components';

const ArtistImage = styled.div.attrs({
  className: 'ArtistImage',
})`
  max-height: 300px;

  display: flex;
  justify-content: center;

  > div {
    display: flex;
    height: 100%;
    width: fit-content;

    position: relative;
  }

  img {
    border-radius: 4px;
    max-height: 100%;
    width: auto;

    object-fit: cover;
  }

  margin-bottom: 24px;
`;

const CheckoutEventTitle = styled.p.attrs({
  className: 'CheckoutEventTitle',
})`
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 12px;
`;

const ArtistNameAndDate = styled.p.attrs({
  className: 'CheckoutArtistNameAndDate',
})`
  text-align: center;
  padding: 0px 8px;
`;

export const EventSummary = ({
  artistImage,
  artistName,
  eventDateTime,
  eventTitle,
}) => {
  return (
    <div style={{ display: 'flex', height: '100%', flexFlow: 'column nowrap', justifyContent: 'space-between'}}>
      <ArtistImage>
        <div className="ArtistGradient"><img src={artistImage} /></div>
      </ArtistImage>
      <CheckoutEventTitle>{eventTitle}</CheckoutEventTitle>
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <ArtistNameAndDate>
          {artistName}
        </ArtistNameAndDate>
        <div className="CheckoutLine" />
        <ArtistNameAndDate>
          {eventDateTime}
        </ArtistNameAndDate>
      </div>
    </div>
  );
};

export default EventSummary;

EventSummary.propTypes = {
  artistImage: PropTypes.string,
  artistName: PropTypes.string,
  eventDateTime: PropTypes.string,
  eventTitle: PropTypes.string,
}
