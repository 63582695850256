import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { formatCentsAsDollarString } from './prices';

const OrderSummaryHeading = styled.div.attrs({
  className: 'OrderSummaryHeading',
})`
  font-size: 20px;
  font-weight: 700;
  color: white;
  text-align: center;
  letter-spacing: 1px;
`;

const CheckoutTotal = styled.div.attrs({
  className: 'CheckoutTotal',
})`
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: white;
  letter-spacing: 1px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;

  @media only screen and (min-width: 800px) {
    height: 72px;
    padding-top: 16px;
  }
`;

const ItemSummary = styled.div.attrs({
  className: 'ItemSummary',
})`
  font-family: Roboto Condensed;
  font-size: 20px;
  font-weight: 400;
  color: white;
  letter-spacing: 1px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
`;

function renderItemSummary({ amount, product: { id, title, price }}) {
  if (amount === 0) {
    return [];
  }
  return [(
    <ItemSummary key={id}>
      <div>{`${amount} x ${title}`}</div>
      <div>{formatCentsAsDollarString(amount * price)}</div>
    </ItemSummary>
  )];
}

export const OrderSummary = ({
  amountsAndProducts,
}) => {
  const totalAmount = useMemo(() => {
    return amountsAndProducts.reduce((sum, current) => {
      return sum + current.amount * current.product.price;
    }, 0);
  }, [amountsAndProducts]);
  return (
    <>
      <div style={{ gridArea: 'order', padding: '0px 36px', height: '100%' }}>
        <div className="CheckoutOrderSummary" style={{ display: 'flex', flexFlow: 'column nowrap', height: '100%' }}>
          <OrderSummaryHeading>
            Order Summary
          </OrderSummaryHeading>
          <div style={{ flex: '1', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'space-evenly' }}>
            {amountsAndProducts.flatMap(renderItemSummary)}
          </div>
        </div>
      </div>
      <div style={{ gridArea: 'hline2' }}>
        <div className="CheckoutLine"/>
      </div>
      <div style={{ gridArea: 'total', padding: '0px 36px' }}>
        <CheckoutTotal>
          <div>Total</div>
          <div>{formatCentsAsDollarString(totalAmount)}</div>
        </CheckoutTotal>
      </div>
    </>
  );
};

OrderSummary.propTypes = {
  amountsAndProducts: PropTypes.array,
};

export default OrderSummary;
