import styled from 'styled-components';

export const CheckoutButton = styled.button.attrs({
  className: 'CheckoutButton',
})`
  background: ${(props) => props.theme.elementStyles.buttonBackground};
  font-family: Roboto Condensed;
  letter-spacing: 2px;
  font-size: 14px;
  font-weight: 400;
  border: 1px white solid;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  text-align: center;
  height: 40px;
  box-sizing: border-box;
  width: 216px;
  text-transform: uppercase;
  border-radius: 4px;
  opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1.0')};

  :hover {
    box-shadow: ${(props) =>
      !props.isDisabled ? '0px 8px 18px 0px rgba(25, 27, 55, 0.4)' : 'unset'};
    transform: ${(props) => (!props.isDisabled ? 'scale(1.05)' : 'unset')};
    opacity: ${(props) => (!props.isDisabled ? '1' : '0.6')};
  }

  @media only screen and (max-width: 799px) {
    margin-bottom: 20px;
  }
`;

export default CheckoutButton;
