import styled from "styled-components";

export const Centered = styled.div.attrs({
  className: "Centered",
})`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.height ?? '100%'};
  width: ${(props) => props.width ?? '100%'};
`;

export default Centered;
