import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import Centered from './Centered';
import CheckoutColumnHeading from './CheckoutColumnHeading';
import Spinner from './Spinner';

import { formatCentsAsDollarString } from "./prices";

const ProductRowGrid = styled.div.attrs({
  className: 'ProductRowGrid',
})`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 8fr 2fr 88px 1fr;
  grid-template-areas:
    ". title . quant ."
    ". desc  . .     .";
  row-gap: 16px;
  justify-content: space-between;
  align-content: space-between;
  align-items: center;

  letter-spacing: 2px;

  padding: 16px 0px;
`;

const CheckoutProductTitle = styled.p.attrs({
  className: 'CheckoutProductTitle',
})`
  text-transform: uppercase;
`;

const CheckoutTicketCounter= styled.div.attrs({
  className: 'CheckoutTicketCounter',
})`
  display: flex;
  border-radius: 4px;
  padding: 4px;
`;

const Button = styled.button.attrs({
  className: 'Button',
})`
  background: transparent;
  font-size: 20px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  min-width: 25px;
  text-align: center;
`;

const MAX_TICKETS = 99;

const Counter = ({
  count,
  setCount,
}) => {
  return (
    <CheckoutTicketCounter>
      <Button onClick={() => {
        setCount((prevValue) => Math.max(prevValue - 1, 0));
      }}>-</Button>
      <div style={{ flex: '1', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{count}</div>
      <Button onClick={() => {
        setCount((prevValue) => Math.min(prevValue + 1, MAX_TICKETS));
      }}>+</Button>
    </CheckoutTicketCounter>
  );
}

Counter.propTypes = {
  count: PropTypes.number,
  setCount: PropTypes.func,
};

const ProductRow = ({
  amount,
  setAmount,
  product,
}) => {
  return (
    <ProductRowGrid key={product.id}>
      <div style={{ gridArea: 'title' }}>
        <CheckoutProductTitle>{product.title}</CheckoutProductTitle>
        <p className="CheckoutProductDesc">{formatCentsAsDollarString(product.price)}</p>
      </div>
      <div style={{ gridArea: 'quant' }}>
        <Counter count={amount} setCount={setAmount} />
      </div>
      <p className="CheckoutProductDesc" style={{ gridArea: 'desc' }}>{product.description}</p>
    </ProductRowGrid>
  );
}

ProductRow.propTypes = {
  amount: PropTypes.number,
  setAmount: PropTypes.func,
  product: PropTypes.object,
}

export const TicketCart = ({
  amountsAndProducts,
  setAmountsAndProducts,
  eventSlug,
}) => {
  const [products, setProducts] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    window.fetch(`/.netlify/functions/get-products-for-event?eventSlug=${eventSlug}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
    }).then(res => {
      return res.json();
    }).then(data => {
      setProducts(data.products);
    }).catch(() => {
      setError('There was an error loading the tickets for this event.');
    });
  }, []);
  useEffect(() => {
    if (products === null) {
      setAmountsAndProducts([]);
      return;
    }
    setAmountsAndProducts(products.map((product) => ({
      amount: 0,
      product,
    })));
  }, [products]);
  const setNewAmount = useCallback((index, newAmountFunc) => {
    setAmountsAndProducts((prevValue) => [
      ...prevValue.slice(0, index),
      { amount: newAmountFunc(prevValue[index].amount), product: prevValue[index].product },
      ...prevValue.slice(index + 1, prevValue.length),
    ]);
  }, [setAmountsAndProducts]);
  if (error != null) {
    return <div>{error}</div>
  }
  if (products == null) {
    return (
      <Centered>
        <Spinner uid="cart" />
      </Centered>
    );
  }
  return (
    <div style={{ display: 'flex', flexFlow: 'column nowrap', height: '100%', justifyContent: 'space-evenly' }}>
      <CheckoutColumnHeading>
        Tickets
      </CheckoutColumnHeading>
      <>
        {amountsAndProducts.map(({ amount, product }, index) => (
          <ProductRow
            key={product.id}
            amount={amount}
            setAmount={(newAmountFunc) => setNewAmount(index, newAmountFunc)}
            product={product}
          />
        ))}
      </>
    </div>
  );
};

TicketCart.propTypes = {
  amountsAndProducts: PropTypes.array,
  setAmountsAndProducts: PropTypes.func,
  eventSlug: PropTypes.string,
};

export default TicketCart;
