import "minireset.css";

// import { IntercomProvider, useIntercom } from "react-use-intercom";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import React, { useLayoutEffect, useMemo, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { format } from "date-fns-tz";

import Layout from "../components/Layout";

import CheckoutModal from "../components/CheckoutModal";
import EventBackground from "../components/EventBackground";
import EventInfo from "../components/EventInfo";
import Footer from "../components/Footer";
import Header from "../components/Header";
import StyledButton from "../components/StyledButton";

// const INTERCOM_APP_ID = "rfp6ptic";

const GlobalStyle = createGlobalStyle`
body {
  background: #000;
}
`;
const Wrapper = styled.div.attrs({
  className: "LVAPageWrapper",
})`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  audio {
    display: none;
  }

  .MobileHeadlineText {
    color: ${(props) => props.$textStyles.mobileHeadlineColor};
  }

  .MobileMoreInfoHeader {
    color: ${(props) => props.$textStyles.mobileMoreInfoHeaderColor};
  }

  .MobileMoreInfo {
    color: ${(props) => props.$textStyles.mobileMoreInfoColor};
  }

  .ShowName {
    font-family: ${(props) => props.$textStyles.titleFont};
    letter-spacing: ${(props) => props.$textStyles.titleLetterSpacing};
  }

  .ArtistName {
    font-family: ${(props) => props.$textStyles.artistNameFont};
    letter-spacing: ${(props) => props.$textStyles.artistNameLetterSpacing};
  }

  .DateTime {
    font-family: ${(props) => props.$textStyles.dateFont};
  }

  .Description {
    font-family: ${(props) => props.$textStyles.descriptionFont};
  }

  .DesktopHeadlineText {
    color: ${(props) => props.$textStyles.desktopTextColor};
    border-color: ${(props) => props.$textStyles.desktopTextColor};
  }

  .ArtistGradient {
    max-width: 600px;
    max-height: 250px;

    width: 100%;
    height: auto;

    img {
      width: 100%;
    }
  }

  .ArtistGradient::before {
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    background-image: ${(props) => props.$artistBannerImageBorderGradient};
    content: "";
    z-index: -1;
    border-radius: 4px;
    box-shadow: 0px 30px 60px 1px rgba(0, 0, 0, 100%);
  }

  @media only screen and (max-width: 799px) {
    .ArtistGradient::before {
      box-shadow: 0px 15px 30px 1px rgba(0, 0, 0, 50%);
    }
  }

  .ArtistGradient::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: ${(props) => props.$artistBannerImageGradient};
  }

  .CheckoutModalBox {
    background: ${(props) => props.checkoutStyles.backgroundColor};
  }

  .CheckoutEventTitle {
    font-family: ${(props) => props.checkoutStyles.titleFont};
    font-size: ${(props) => props.checkoutStyles.titleSize};
    line-height: ${(props) => props.checkoutStyles.titleLineHeight};
    font-weight: ${(props) => props.checkoutStyles.titleWeight};
    letter-spacing: ${(props) => props.checkoutStyles.titleLetterSpacing};
    color: ${(props) => props.checkoutStyles.titleColor};
  }

  .CheckoutArtistNameAndDate {
    font-family: ${(props) => props.checkoutStyles.nameAndDateFont};
    font-size: ${(props) => props.checkoutStyles.nameAndDateSize};
    line-height: ${(props) => props.checkoutStyles.nameAndDateLineHeight};
    font-weight: ${(props) => props.checkoutStyles.nameAndDateWeight};
    letter-spacing: ${(props) => props.checkoutStyles.nameAndDateLetterSpacing};
    color: ${(props) => props.checkoutStyles.nameAndDateColor};
  }

  .CheckoutProductTitle {
    font-family: ${(props) => props.checkoutStyles.productTitleFont};
    font-size: ${(props) => props.checkoutStyles.productTitleSize};
    line-height: ${(props) => props.checkoutStyles.productTitleLineHeight};
    font-weight: ${(props) => props.checkoutStyles.productTitleWeight};
    letter-spacing: ${(props) =>
      props.checkoutStyles.productTitleLetterSpacing};
    color: ${(props) => props.checkoutStyles.productTitleColor};
  }

  .CheckoutProductDesc {
    font-family: ${(props) => props.checkoutStyles.productDescFont};
    font-size: ${(props) => props.checkoutStyles.productDescSize};
    line-height: ${(props) => props.checkoutStyles.productDescLineHeight};
    font-weight: ${(props) => props.checkoutStyles.productDescWeight};
    letter-spacing: ${(props) => props.checkoutStyles.productDescLetterSpacing};
    color: ${(props) => props.checkoutStyles.productDescColor};
  }

  .CheckoutLine {
    border: 1px ${(props) => props.checkoutStyles.lineColor} solid;
  }

  .CheckoutSuccessMessage {
    font-family: ${(props) => props.checkoutStyles.successMessageFont};
    font-size: ${(props) => props.checkoutStyles.successMessageSize};
    line-height: ${(props) => props.checkoutStyles.successMessageLineHeight};
    font-weight: ${(props) => props.checkoutStyles.successMessageWeight};
    letter-spacing: ${(props) =>
      props.checkoutStyles.successMessageLetterSpacing};
    color: ${(props) => props.checkoutStyles.successMessageColor};
  }

  .CheckoutColumnHeading {
    font-family: ${(props) => props.checkoutStyles.columnHeadingFont};
    font-size: ${(props) => props.checkoutStyles.columnHeadingSize};
    line-height: ${(props) => props.checkoutStyles.columnHeadingLineHeight};
    font-weight: ${(props) => props.checkoutStyles.columnHeadingWeight};
    letter-spacing: ${(props) =>
      props.checkoutStyles.columnHeadingLetterSpacing};
    color: ${(props) => props.checkoutStyles.columnHeadingColor};
  }

  .CheckoutSectionHeading {
    color: ${(props) => props.checkoutStyles.columnHeadingColor};
  }

  .CheckoutContainer {
    font-family: ${(props) => props.checkoutStyles.font};
  }

  .CheckoutOrderSummary {
    color: ${(props) => props.checkoutStyles.summaryColor};
  }

  .CheckoutTotal {
    color: ${(props) => props.checkoutStyles.summaryColor};
  }

  .CheckoutTicketCounter {
    background: ${(props) => props.checkoutStyles.counterBackgroundColor};

    > * {
      color: ${(props) => props.checkoutStyles.counterTextColor};
    }
  }
`;

const EventContainer = styled.div.attrs({
  className: "EventContainer",
})`
  display: flex;
  flex: 1;
  position: relative;
  flex-flow: column;

  margin-top: ${({ isScrollable, scrollY }) =>
    isScrollable ? "0px" : `-${scrollY}px`};
  max-height: ${({ isScrollable, scrollY }) =>
    isScrollable ? "100%" : `calc(100vh + ${scrollY}px)`};
  overflow: ${({ isScrollable }) => (isScrollable ? "unset" : "hidden")};

  @media only screen and (min-width: 800px) {
    height: clamp(600px, 80vh, 1000px);
    width: clamp(800px, 95vw, 2048px);
    max-height: 800px;
  }
`;

export const ThemeContext = React.createContext();

export const LVAPageTemplate = ({
  eventSlug,
  title,
  description,
  moreInfo,
  artistName,
  artistImage,
  artistBannerImage,
  artistBannerImageGradient,
  artistBannerImageBorderGradient,
  mobileMoreInfoBackgroundColor,
  mobileBackgroundTransitionColor,
  audioClip,
  desktopBackgroundImage,
  desktopBackgroundColor,
  eventDateTime,
  textStyles,
  checkoutStyles,
  animationStyles,
  elementStyles,
}) => {
  // Here is where we can do fine-grained control of Intercom
  // const { boot, shutdown, hide, show, update } = useIntercom();
  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);
  const [lazyScrollY, setLazyScrollY] = useState(0);

  useLayoutEffect(() => {
    if (!isCheckoutModalOpen) {
      window.scroll(0, lazyScrollY);
      setLazyScrollY(0);
    }
  }, [isCheckoutModalOpen]);
  const parsed = new Date(eventDateTime);
  const audioRef = useRef(null);

  if (!elementStyles || !textStyles || !animationStyles) {
    console.warn(
      "You did not include all the styles necessary for the page! Go into the CMS and add them.",
    );
  }
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const formattedEventDateTime = format(parsed, "iii MMMM dd y h:mm zzz", {
    timeZone,
  });

  const ticketButton = (
    <StyledButton
      buttonText="Tickets"
      fontName={elementStyles.buttonFont}
      textColor={elementStyles.buttonTextColor}
      background={elementStyles.buttonBackground}
      height="36px"
      width="176px"
      onClick={() => {
        setLazyScrollY(window.scrollY);
        setIsCheckoutModalOpen(true);
      }}
    />
  );

  // TOOO add default theme values
  const theme = {
    elementStyles,
  };

  const fonts = useMemo(
    () =>
      Array.from(
        /** Gets all unique fonts from the configuration, then fetches them from Google fonts and loads them in a head tag. */
        new Set(
          [
            elementStyles.buttonFont,
            textStyles.titleFont,
            textStyles.descriptionFont,
            textStyles.dateFont,
            checkoutStyles.titleFont,
            checkoutStyles.nameAndDateFont,
            checkoutStyles.productTitleFont,
            checkoutStyles.productDescFont,
            checkoutStyles.font,
            checkoutStyles.successMessageFont,
            checkoutStyles.columnHeadingFont,
            "Roboto Condensed",
          ]
            .filter((font) => font)
            .map((font) => font.replace(/'/g, "").split(" ").join("+")),
        ),
      ),
    [elementStyles, checkoutStyles, textStyles],
  );

  return (
    <>
      <GlobalStyle />
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        {fonts.map((font) => (
          <link
            key={font}
            href={`https://fonts.googleapis.com/css2?family=${font}:wght@300;400;700&display=swap`}
            rel="stylesheet"
          ></link>
        ))}
      </Helmet>
      <ThemeProvider theme={theme}>
        <Wrapper
          $textStyles={textStyles}
          $artistBannerImageGradient={artistBannerImageGradient}
          $artistBannerImageBorderGradient={artistBannerImageBorderGradient}
          checkoutStyles={checkoutStyles}
        >
          <Header />
          <EventContainer
            isScrollable={!isCheckoutModalOpen}
            scrollY={lazyScrollY}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: "100%",
                zIndex: -1,
              }}
            >
              <EventBackground
                artistImage={artistImage}
                mobileMoreInfoBackgroundColor={mobileMoreInfoBackgroundColor}
                mobileBackgroundTransitionColor={
                  mobileBackgroundTransitionColor
                }
                desktopBackgroundImage={desktopBackgroundImage}
                desktopBackgroundColor={desktopBackgroundColor}
              />
            </div>
            <EventInfo
              showName={title}
              artistName={artistName}
              artistBannerImage={artistBannerImage}
              description={description}
              eventDatetimeString={eventDateTime}
              isScrollable={!isCheckoutModalOpen}
              moreInfo={moreInfo}
              onTicketsClick={() => {
                setLazyScrollY(window.scrollY);
                setIsCheckoutModalOpen(true);
              }}
              ticketButton={ticketButton}
              worldDebutStyles={elementStyles}
            />
          </EventContainer>

          <Footer />

          <CheckoutModal
            isOpen={isCheckoutModalOpen}
            onCloseClick={() => {
              setIsCheckoutModalOpen(false);
            }}
            onOverlayClick={() => {
              setIsCheckoutModalOpen(false);
            }}
            artistImage={artistBannerImage}
            eventSlug={eventSlug}
            eventTitle={title}
            artistName={artistName}
            eventDateTime={formattedEventDateTime}
          />

          {/* Invisible audio player */}
          {audioClip && (
            <audio ref={audioRef} loop>
              <source src={audioClip.publicURL} type="audio/mp3"></source>
            </audio>
          )}
        </Wrapper>
      </ThemeProvider>
    </>
  );
};

LVAPageTemplate.propTypes = {
  eventSlug: PropTypes.string,
  title: PropTypes.string,
  eventDateTime: PropTypes.string,
  description: PropTypes.string,
  artistName: PropTypes.string,
  artistImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  artistBannerImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  artistBannerImageGradient: PropTypes.string,
  artistBannerImageBorderGradient: PropTypes.string,
  moreInfo: PropTypes.string,
  mobileMoreInfoBackgroundColor: PropTypes.string,
  mobileBackgroundTransitionColor: PropTypes.string,
  checkoutBackgroundColor: PropTypes.string,
  audioClip: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  desktopBackgroundImage: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  desktopBackgroundColor: PropTypes.string,
  elementStyles: PropTypes.object,
  textStyles: PropTypes.object,
  checkoutStyles: PropTypes.object,
  animationStyles: PropTypes.object,
};

function chopSlug(slug) {
  const lastDelimiterIndex = slug.length - 1;
  const secondToLastDelimiterIndex = slug.lastIndexOf(
    "/",
    lastDelimiterIndex - 1,
  );
  return slug.slice(secondToLastDelimiterIndex + 1, lastDelimiterIndex);
}

const LVAPage = ({ data }) => {
  const {
    frontmatter,
    fields: { slug },
  } = data.markdownRemark;
  const eventSlug = chopSlug(slug);

  // Normalizes image src, since preview sometimes sends a string rather than object
  // TODO should move this outside the component
  const artistSrc =
    typeof frontmatter.artistImage === "string"
      ? frontmatter.artistImage
      : frontmatter.artistImage.childImageSharp.fluid.src;
  const desktopBackgroundSrc =
    typeof frontmatter.desktopBackgroundImage === "string"
      ? frontmatter.desktopBackgroundImage
      : frontmatter.desktopBackgroundImage.childImageSharp.fluid.src;
  const artistBannerSrc =
    typeof frontmatter.artistBannerImage === "string"
      ? frontmatter.artistBannerImage
      : frontmatter.artistBannerImage.childImageSharp.fluid.src;

  return (
    <Layout>
      {/* <IntercomProvider appId={INTERCOM_APP_ID} autoBoot> */}
      <LVAPageTemplate
        eventSlug={eventSlug}
        title={frontmatter.title}
        description={frontmatter.description}
        artistName={frontmatter.artistName}
        artistImage={artistSrc}
        artistBannerImage={artistBannerSrc}
        artistBannerImageGradient={frontmatter.artistBannerImageGradient}
        artistBannerImageBorderGradient={
          frontmatter.artistBannerImageBorderGradient
        }
        moreInfo={frontmatter.moreInfo}
        mobileMoreInfoBackgroundColor={
          frontmatter.mobileMoreInfoBackgroundColor
        }
        mobileBackgroundTransitionColor={
          frontmatter.mobileBackgroundTransitionColor
        }
        audioClip={frontmatter.audioClip}
        desktopBackgroundImage={desktopBackgroundSrc}
        desktopBackgroundColor={frontmatter.desktopBackgroundColor}
        eventDateTime={frontmatter.eventDateTime}
        textStyles={frontmatter.textStyles}
        checkoutStyles={frontmatter.checkoutStyles}
        elementStyles={frontmatter.elementStyles}
        animationStyles={frontmatter.animationStyles}
      />
      {/* </IntercomProvider> */}
    </Layout>
  );
};

LVAPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      fields: {
        slug: PropTypes.string,
      },
      frontmatter: PropTypes.object,
    }),
  }),
};

export default LVAPage;

export const lvaPageQuery = graphql`
  query LVAPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        title
        eventDateTime
        description
        moreInfo
        artistName
        audioClip {
          publicURL
        }
        desktopBackgroundImage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        desktopBackgroundColor
        artistImage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        artistBannerImage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        artistBannerImageGradient
        artistBannerImageBorderGradient
        mobileBackgroundTransitionColor
        mobileMoreInfoBackgroundColor
        animationStyles {
          pulseRadialColor
          pulseAnimationRate
        }
        showStyles {
          gradientColor
        }
        elementStyles {
          buttonFont
          buttonTextColor
          buttonBackground
        }
        textStyles {
          titleFont
          titleLetterSpacing
          artistNameFont
          artistNameLetterSpacing
          descriptionFont
          dateFont
          mobileHeadlineColor
          mobileMoreInfoHeaderColor
          mobileMoreInfoColor
          desktopTextColor
        }
        checkoutStyles {
          titleFont
          titleColor
          titleLetterSpacing
          titleWeight
          titleSize
          titleLineHeight
          nameAndDateFont
          nameAndDateColor
          nameAndDateLetterSpacing
          nameAndDateWeight
          nameAndDateSize
          nameAndDateLineHeight
          productTitleFont
          productTitleColor
          productTitleLetterSpacing
          productTitleWeight
          productTitleSize
          productTitleLineHeight
          productDescFont
          productDescColor
          productDescLetterSpacing
          productDescWeight
          productDescSize
          productDescLineHeight
          backgroundColor
          lineColor
          font
          summaryColor
          successMessageFont
          successMessageColor
          successMessageLetterSpacing
          successMessageWeight
          successMessageSize
          successMessageLineHeight
          columnHeadingFont
          columnHeadingColor
          columnHeadingLetterSpacing
          columnHeadingWeight
          columnHeadingSize
          columnHeadingLineHeight
          counterBackgroundColor
          counterTextColor
        }
      }
    }
  }
`;
