import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

import { CardElement } from "@stripe/react-stripe-js";

import CheckoutColumnHeading from './CheckoutColumnHeading';
import { isStringEmpty } from './string-utils';

const CheckoutSectionHeading = styled.p.attrs({
  className: 'CheckoutSectionHeading',
})`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 1px;

  padding-bottom: 16px;
`;

const StripeInputWrapper = styled.div.attrs({
  className: "StripeInputWrapper",
})`
  padding: 6px;
  border-radius: 2px;
  background-color: #c2c2c2;
  border: ${({ hasError }) => hasError ? '2px #B00000 solid' : '2px transparent solid'};
  box-sizing: border-box;
`;

const Input = styled.input.attrs({
  className: "Input",
})`
  flex: 1;

  padding: 6px 14px;
  border-radius: 2px;
  background-color: #c2c2c2;
  border: ${({ hasError }) => hasError ? '2px #B00000 solid' : '2px transparent solid'};
  box-sizing: border-box;

  min-width: 0px;

  outline: none;

  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  color: ${({ hasError }) => hasError ? '#B00000' : '#000000'};

  ::placeholder {
    font-weight: 400;
    color: ${({ hasError }) => hasError ? '#B00000' : '#757575'};
  }

  :focus {
    color: #000000;
  }

  :focus::placeholder {
    color: #757575;
  }
`;

const ErrorText = styled.div.attrs({
  className: 'ErrorText',
})`
  position: absolute;
  margin-top: 8px;
  margin-left: 16px;
  font-size: 14px;
  font-family: sans-serif;
  font-weight: 400;
  color: #FF0000;
  letter-spacing: 1px;
`;

const Disclaimer = styled.div.attrs({
  className: 'Disclaimer',
})`
  font-size: 12px;
  color: ${(props) => props.color ?? '#C2C2C2'};
  text-align: center;
`;
const disclaimerText = 'By clicking "Purchase", I accept the Terms of Service and have read the Privacy Policy.  I agree that Okidoki may share my information with the event organizer.';

export default function CheckoutForm({
  contactInfo,
  setContactInfo,
  backendError,
  contactInfoErrors,
  setContactInfoErrors,
  paymentError,
  setPaymentError,
}) {
  const [emailConfirmationError, setEmailConfirmationError] = useState(null);

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    if (event.error === undefined) {
      setPaymentError("");
    } else {
      setPaymentError(event.error.message);
    }
  };

  const cardStyle = {
    style: {
      base: {
        backgroundColor: '#c2c2c2',
        iconColor: '#000000',
        color: '#000000',
        fontWeight: 700,
        fontSize: '16px',
        fontSmoothing: 'anti-aliased',
        ':-webkit-autofill': {
          color: '#7c6803',
        },
        '::placeholder': {
          color: '#757575',
        }
      },
      invalid: {
        iconColor: '#B00000',
        color: '#B00000',
      }
    }
  };

  function setContactInfoField(fieldName, value) {
    setContactInfo((prevValue) => ({
      ...prevValue,
      [fieldName]: value,
    }));
  }

  function checkContactInfo(fieldName, ariaLabel) {
    if (isStringEmpty(contactInfo[fieldName])) {
      setContactInfoErrors((prevValue) => ({
        ...prevValue,
        [fieldName]: `${ariaLabel} is required`,
      }));
    }
  }

  function clearContactInfoError(fieldName) {
    setContactInfoErrors((prevValue) => ({
      ...prevValue,
      [fieldName]: null,
    }));
  }

  function checkEmailConfirmation(allowEmpty) {
    if (isStringEmpty(contactInfo.email)) {
      if (!isStringEmpty(contactInfo.emailConfirmation)) {
        setEmailConfirmationError('Email confirmation does not match');
      }
    } else {
      if (isStringEmpty(contactInfo.emailConfirmation)) {
        if (!allowEmpty) {
          setEmailConfirmationError('Email confirmation does not match');
        }
      } else {
        if (contactInfo.email !== contactInfo.emailConfirmation) {
          setEmailConfirmationError('Email confirmation does not match');
        }
      }
    }
  }

  return (
    <div style={{ height: '100%', width: '100%', maxWidth: '416px', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'space-evenly' }}>
      <CheckoutColumnHeading>
        Checkout
      </CheckoutColumnHeading>
      <section style={{ paddingBottom: '16px' }}>
        <CheckoutSectionHeading>
          Contact Info
        </CheckoutSectionHeading>
        <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between', marginBottom: '16px' }}>
          <Input
            type="text"
            name="firstName"
            aria-label="First name"
            aria-required="true"
            placeholder="First name*"
            hasError={!isStringEmpty(contactInfoErrors['firstName'])}
            value={contactInfo.firstName}
            onFocus={() => clearContactInfoError('firstName')}
            onChange={(event) => setContactInfoField('firstName', event.target.value)}
            onBlur={() => checkContactInfo('firstName', 'First name')}
          />
          <div style={{ flex: '0 0 16px' }}/>
          <Input
            type="text"
            name="lastName"
            aria-label="Last name"
            aria-required="true"
            placeholder="Last name*"
            hasError={!isStringEmpty(contactInfoErrors['lastName'])}
            value={contactInfo.lastName}
            onFocus={() => clearContactInfoError('lastName')}
            onChange={(event) => setContactInfoField('lastName', event.target.value)}
            onBlur={() => checkContactInfo('lastName', 'Last name')}
          />
        </div>
        <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between', marginBottom: '16px' }}>
          <Input
            type="text"
            name="email"
            aria-label="Email"
            aria-required="true"
            placeholder="Email*"
            hasError={!isStringEmpty(contactInfoErrors['email'])}
            value={contactInfo.email}
            onFocus={() => {
              clearContactInfoError('email');
              setEmailConfirmationError(null);
            }}
            onChange={(event) => setContactInfo({ ...contactInfo, email: event.target.value })}
            onBlur={() => {
              checkContactInfo('email', 'Email');
              checkEmailConfirmation(true);
            }}
          />
        </div>
        <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}>
          <Input
            type="text"
            name="emailConfirmation"
            aria-label="Email confirmation"
            aria-required="true"
            placeholder="Confirm email*"
            hasError={!isStringEmpty(contactInfoErrors['emailConfirmation']) || !isStringEmpty(emailConfirmationError)}
            value={contactInfo.emailConfirmation}
            onFocus={() => {
              clearContactInfoError('emailConfirmation');
              setEmailConfirmationError(null);
            }}
            onChange={(event) => setContactInfo({ ...contactInfo, emailConfirmation: event.target.value })}
            onPaste={(event) => { event.preventDefault(); }}
            onBlur={() => {
              checkContactInfo('emailConfirmation', 'Email confirmation');
              checkEmailConfirmation(false);
            }}
          />
        </div>
        <div style={{ position: 'relative'}}>
          <ErrorText>
            {[backendError, ...Object.values(contactInfoErrors), emailConfirmationError].find((s) => !isStringEmpty(s))}
          </ErrorText>
        </div>
      </section>
      <section style={{ paddingBottom: '16px' }}>
        <CheckoutSectionHeading>
          Payment
        </CheckoutSectionHeading>
        <StripeInputWrapper hasError={!isStringEmpty(paymentError)}>
          <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
        </StripeInputWrapper>
        <div style={{ position: 'relative'}}>
          <ErrorText>
            {paymentError}
          </ErrorText>
        </div>
      </section>
      <Disclaimer>
        {disclaimerText}
      </Disclaimer>
    </div>
  )
}

CheckoutForm.propTypes = {
  payment: PropTypes.object,
  contactInfo: PropTypes.object,
  setContactInfo: PropTypes.func,
  backendError: PropTypes.string,
  contactInfoErrors: PropTypes.object,
  setContactInfoErrors: PropTypes.func,
  paymentError: PropTypes.string,
  setPaymentError: PropTypes.func,
};
