import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import Centered from "./Centered";
import CheckoutButton from "./CheckoutButton";
import Checkmark from "./Checkmark";
import CheckoutForm from "./CheckoutForm";
import Spinner from "./Spinner";
import TicketCart from "./TicketCart";
import { useCreatePaymentIntent } from "./useCreatePaymentIntent";
import { isStringEmpty } from "./string-utils";

const SlidingViewport = styled.div.attrs({
  className: "SlidingViewport",
})`
  display: flex;
  flex-flow: row nowrap;

  overflow: hidden;

  height: 100%;
`;

const SlidingPanel = styled.div.attrs({
  className: "SlidingPanel",
})`
  flex: 0 0 100%;
  min-width: 0px;

  transform: translateX(${({ activeIndex }) => `${-1 * activeIndex * 100}%`});
  transition: transform 0.5s ease 0s;

  display: flex;
  flex-flow: column nowrap;
`;

const CheckoutSuccessMessage = styled.p.attrs({
  className: "CheckoutSuccessMessage",
})`
  text-align: center;

  padding: 8px 0px;
  max-width: 480px;
`;

export const SlidingForm = ({
  amountsAndProducts,
  setAmountsAndProducts,
  eventSlug,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [activeIndex, setActiveIndex] = useState(0);

  const {
    createPaymentIntent,
    result: { payment, isLoading, error },
  } = useCreatePaymentIntent();
  const [contactInfo, setContactInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    emailConfirmation: "",
  });
  const [contactInfoErrors, setContactInfoErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
    emailConfirmation: null,
  });
  const [backendError, setBackendError] = useState(null);
  const [paymentError, setPaymentError] = useState(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [isSucceeded, setIsSucceeded] = useState(false);

  const onCheckOutClick = (event) => {
    event.preventDefault();
    if (isDisabled) {
      return;
    }
    createPaymentIntent({ amountsAndProducts });
  };
  const onPurchaseClick = async (event) => {
    event.preventDefault();
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);

    if (Object.values(contactInfoErrors).some((s) => !isStringEmpty(s))) {
      setTimeout(() => {
        setIsProcessing(false);
      }, 300);
      return;
    }

    const response = await window.fetch(
      "/.netlify/functions/save-contact-info",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ payment, contactInfo }),
      },
    );
    if (!response.ok) {
      console.error(response);
      setBackendError(
        "We apologize, but there was an error.  Please try again",
      );
      setTimeout(() => {
        setIsProcessing(false);
      }, 300);
      return;
    }
    setBackendError(null);

    const payload = await stripe.confirmCardPayment(payment.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (payload.error) {
      setPaymentError(`Payment failed: ${payload.error.message}`);
      setIsProcessing(false);
    } else {
      setPaymentError("");
      setIsProcessing(false);
      setIsSucceeded(true);
    }
  };

  const isDisabled = useMemo(() => {
    if (isLoading) {
      return true;
    }
    if (payment != null) {
      return true;
    }
    if (amountsAndProducts.some(({ amount }) => amount > 0)) {
      return false;
    }
    return true;
  }, [isLoading, amountsAndProducts, payment]);
  useEffect(() => {
    if (isSucceeded) {
      setActiveIndex(3);
    } else if (isLoading) {
      setActiveIndex(1);
    } else if (!isLoading && payment != null && error == null) {
      setActiveIndex(2);
    } else {
      setActiveIndex(0);
    }
  }, [isLoading, payment, isSucceeded, error]);

  return (
    <>
      <div style={{ gridArea: "form", minWidth: "0px", height: "100%" }}>
        <SlidingViewport>
          <SlidingPanel activeIndex={activeIndex}>
            <TicketCart
              amountsAndProducts={amountsAndProducts}
              setAmountsAndProducts={setAmountsAndProducts}
              eventSlug={eventSlug}
            />
          </SlidingPanel>
          <SlidingPanel activeIndex={activeIndex}>
            <Centered>
              <Spinner uid="intermediate" />
            </Centered>
          </SlidingPanel>
          <SlidingPanel activeIndex={activeIndex}>
            <Centered>
              <CheckoutForm
                contactInfo={contactInfo}
                setContactInfo={setContactInfo}
                backendError={backendError}
                contactInfoErrors={contactInfoErrors}
                setContactInfoErrors={setContactInfoErrors}
                paymentError={paymentError}
                setPaymentError={setPaymentError}
              />
            </Centered>
          </SlidingPanel>
          <SlidingPanel activeIndex={activeIndex}>
            <Centered>
              <div
                style={{
                  display: "flex",
                  flexFlow: "column nowrap",
                  alignItems: "center",
                }}
              >
                <div style={{ marginBottom: "12px" }}>
                  <Checkmark
                    borderWidth="8px"
                    height="40px"
                    width="24px"
                    borderColor="#C2C2C2"
                  />
                </div>
                <CheckoutSuccessMessage>
                  {"You're all set!"}
                </CheckoutSuccessMessage>
                <CheckoutSuccessMessage>
                  Your tickets will arrive in your inbox shortly.
                </CheckoutSuccessMessage>
              </div>
            </Centered>
          </SlidingPanel>
        </SlidingViewport>
      </div>
      <div style={{ gridArea: "hline1" }}>
        <SlidingViewport>
          <SlidingPanel activeIndex={activeIndex}>
            <div className="CheckoutLine" />
          </SlidingPanel>
          <SlidingPanel activeIndex={activeIndex} />
          <SlidingPanel activeIndex={activeIndex}>
            <div className="CheckoutLine" />
          </SlidingPanel>
          <SlidingPanel activeIndex={activeIndex} />
        </SlidingViewport>
      </div>
      <div
        style={{
          gridArea: "button",
          minWidth: "0px",
          height: "100%",
          position: !isDisabled ? "sticky" : "relative",
          bottom: "-5%",
        }}
      >
        <SlidingViewport>
          <SlidingPanel activeIndex={activeIndex}>
            <Centered>
              {!isDisabled && (
                <CheckoutButton
                  isDisabled={isDisabled}
                  onClick={onCheckOutClick}
                >
                  {isLoading ? <Spinner size="sm" uid="button" /> : "Check Out"}
                </CheckoutButton>
              )}
            </Centered>
          </SlidingPanel>
          <SlidingPanel activeIndex={activeIndex} />
          <SlidingPanel activeIndex={activeIndex}>
            <Centered>
              <CheckoutButton
                isDisabled={isProcessing || isSucceeded}
                onClick={onPurchaseClick}
              >
                {isProcessing ? (
                  <Spinner size="sm" uid="button" />
                ) : isSucceeded ? (
                  <Checkmark
                    borderWidth="6px"
                    height="20px"
                    width="12px"
                    borderColor="#C2C2C2"
                  />
                ) : (
                  "Purchase"
                )}
              </CheckoutButton>
            </Centered>
          </SlidingPanel>
          <SlidingPanel activeIndex={activeIndex} />
        </SlidingViewport>
      </div>
    </>
  );
};

SlidingForm.propTypes = {
  amountsAndProducts: PropTypes.array,
  setAmountsAndProducts: PropTypes.func,
  eventSlug: PropTypes.string,
};

export default SlidingForm;
